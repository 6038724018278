import { useSignal } from "@preact/signals";

export default function LoginForm({changeForm}: {changeForm: (formName: string) => void}) {
    const login = useSignal("")
    const password = useSignal("")
    const error = useSignal("")

    async function sendLoginRequest() {
        const result = await fetch("/api/login", {
            method: "POST",
            body: JSON.stringify({
                login: login.value,
                password: password.value
            })
        })

        const data = await result.json()
        console.log(data);
        
        if (data.success)
            window.location.replace("/")
        else
            error.value = data.reason
    }

    return (
        <div class="card mx-auto my-2 register shadow max-w-[400px]">
            <div class="card-body d-flex flex-column justify-content-between align-items-center gap-2">
                <div>
                    <h3 class="text-center mb-0 text-2xl font-semibold">Вход</h3>
                    <p class="text-center">Введите e-mail и пароль</p> 
                </div>
                {error.value && <div class="alert alert-danger text-center mx-auto">{error.value}</div>}

                <input type="email" name="username" required class="form-control mt-2 mx-2" placeholder="Логин" value={login.value} onInput={e => login.value = e.currentTarget.value} />
                <input type="password" name="password" required class="form-control mb-2 mx-2" placeholder="Пароль" value={password.value} onInput={e => password.value = e.currentTarget.value} />
                <button onClick={() => sendLoginRequest()} class="btn btn-primary" style="padding: 10px 25px">Войти</button>
                <button onClick={() => changeForm("register")} class="text-blue-400 hover:text-blue-600 transition-colors text-decoration-none">Еще нет аккаунта? Создать</button>
                <button onClick={() => window.location.replace("/forgot")} class="text-blue-400 hover:text-blue-600 transition-colors text-decoration-none">Забыли пароль?</button>
            </div>
        </div>
    )
}
